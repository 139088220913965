<template>
  <div class="qrCode">
    <mt-header :title="$t('exclusiveInvitationQrCode')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div class="title">{{ $t('qrCodeTitle') }}</div>
      <div id="qrCode" class="qrcode_code" ref="qrCodeDiv"></div>
      <div class="tips">{{ $t('qrCodeTips') }}</div>
    </div>
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import QRCode from 'qrcodejs2'
export default {
  name: 'QrCode',
  components: {
    callPhone,
  },
  mounted() {
    new QRCode(this.$refs.qrCodeDiv, {
      text: this.$route.query.url || '',
      width: 200,
      height: 200,
      colorDark: "#333333", //二维码颜色
      colorLight: "#ffffff", //二维码背景色
      correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
    });
  }
}
</script>

<style lang="scss" scoped>
.qrCode {
  min-height: 100vh;
  background-image: url('../../assets/qrCodeBg.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-color: #e3563f;
  .content {
    margin-top: 25vw;
    margin-left: 15vw;
    margin-right: 15vw;
    text-align: center;
    .title {
      font-weight: bold;
      font-size: 25px;
      color: #E3553F;
      margin-bottom: 30px;
    }
    .qrcode_code {
      width: 200px;
      margin: 0 auto;
    }
    .tips {
      margin-top: 70px;
      font-size: 12px;
      color: #FADC3E;
      line-height: 25px;
    }
  }
}
</style>